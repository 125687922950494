// extracted by mini-css-extract-plugin
export var category = "PostList-module--category--22393";
export var container = "PostList-module--container--f1e1d";
export var content = "PostList-module--content--de4e3";
export var date = "PostList-module--date--21238";
export var header = "PostList-module--header--5ba1b";
export var inner = "PostList-module--inner--ef88a";
export var item = "PostList-module--item--95839";
export var sub = "PostList-module--sub--424ed";
export var tag = "PostList-module--tag--0b5cb";
export var thum = "PostList-module--thum--e2052";
export var title = "PostList-module--title--a2e55";
export var wrapper = "PostList-module--wrapper--f39d6";