import React from "react";
import { Link, navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as styles from "./PostList.module.scss";

import { AllPostNodeType, PostFrontmatterType } from "@components/Post/type";
import TagList from "@components/Tag/TagList";

const PostItem = ({
  frontmatter: { title, subtitle, date, category, tags, thumbnail },
  fields: { slug },
}: PostFrontmatterType) => {
  const handleCategoryClick = (event: React.MouseEvent, category: string) => {
    event.preventDefault();
    navigate(`/category/${category.replace(" ", "-")}`);
  };

  return (
    <li className={styles.item}>
      <Link className={styles.wrapper} to={`${slug}`}>
        <div className={styles.thum}>
          <GatsbyImage
            image={getImage(thumbnail?.childImageSharp?.gatsbyImageData)!}
            alt={`올리브영 테크블로그 포스팅 ${title}`}
            className={styles.inner}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.header}>
            <span onClick={(event) => handleCategoryClick(event, category)}
              className={styles.category}>{category}</span>
            <span className={styles.date}>{date}</span>
          </div>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.sub}>{subtitle}</p>
          {tags && (
            <div className={styles.tag}>
              <TagList tags={tags} />
            </div>
          )}
        </div>
      </Link>
    </li>
  );
};

const PostList = ({ nodes }: AllPostNodeType) => {
  return (
    <ul className={styles.container}>
      {nodes.map((data, index) => (
        <PostItem {...data} key={index} />
      ))}
    </ul>
  );
};

export default PostList;
